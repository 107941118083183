export function isEmptyField(content) {
	if (typeof content === 'string' && content.trim().length > 0) {
		return false;
	} else if (typeof content === 'number') {
		return false;
	} else {
		return true;
	}
}

export function isTextOnly(content) {
	const regexFirstLastName = /^[A-zÀ-ÖØ-öø-ÿ\s'-.]+$/;
	return regexFirstLastName.test(content);
}

export function isNumbersOnly(content) {
	const regexNumbersOnly = /^[0-9]+$/;
	return regexNumbersOnly.test(content);
}

export function isEmailFormat(content) {
	const regexEmail = /^[\w.%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
	return regexEmail.test(content);
}

export function isPhoneNumber(content, countryCode) {
	let regexPhone;
	if (
		countryCode.toLowerCase() === 'ca' ||
		countryCode.toLowerCase() === 'us'
	) {
		regexPhone = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/;
	} else {
		regexPhone = /^[\+]?\d{3,20}$/;
	}
	return regexPhone.test(content);
}

// * This method will validate the files by comparing the mime types of the files
// * So in the input element we need a mime type in the accept field
// * See https://developer.mozilla.org/en-US/docs/Web/HTTP/Basics_of_HTTP/MIME_types for valid types
export function isFileTypeMatching(type, acceptedTypes) {
	const arrayAcceptedTypes = acceptedTypes.replace(/\s/g, '').split(',');
	let matching = false;
	arrayAcceptedTypes.forEach((acceptedType) => {
		if (type === acceptedType) {
			matching = true;
		}
	});
	return matching;
}

export function isWebAddress(content) {
	const regexURL = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/;
	return regexURL.test(content);
}
