export function returnFileSize(number) {
	if (number < 1024) {
		return number + 'bytes';
	} else if (number >= 1024 && number < 1048576) {
		return (number / 1024).toFixed(1) + 'KB';
	} else if (number >= 1048576) {
		return (number / 1048576).toFixed(1) + 'MB';
	}
}

export function cleanJson(inputStringJson) {
	let sanitized = inputStringJson
		.replace(/\\n/g, '\\n')
		.replace(/\\'/g, "\\'")
		.replace(/\\"/g, '\\"')
		.replace(/\\&/g, '\\&')
		.replace(/\\r/g, '\\r')
		.replace(/\\t/g, '\\t')
		.replace(/\\b/g, '\\b')
		.replace(/\\f/g, '\\f');

	// remove non-printable and other non-valid JSON chars
	sanitized = sanitized.replace(/[\u0000-\u0019]+/g, '');
	return JSON.parse(sanitized);
}

export function ellipsis(length, el) {
	if (el.length > length) {
		for (let i = length; i > 0; i--) {
			if (
				el.charAt(i - 1) != ',' ||
				el.charAt(i - 1) != '.' ||
				el.charAt(i - 1) != ';'
			) {
				return el.substr(0, i) + (el.length > length ? '...' : '');
			}
		}
	} else {
		return el;
	}
}
