/* eslint-disable prefer-destructuring */
/* eslint-disable no-redeclare */
/* eslint-disable no-lonely-if */
/* eslint-disable no-shadow */
/* eslint-disable eqeqeq */
/* eslint-disable no-var */
/* eslint-disable vars-on-top */
/* eslint-disable no-prototype-builtins */
/* eslint-disable block-scoped-var */
/* eslint-disable no-plusplus */
/* eslint-disable spaced-comment */
/* eslint-disable prefer-template */
/* eslint-disable no-restricted-globals */
/* eslint-disable no-else-return */
/* eslint-disable no-useless-return */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-useless-escape */
/* eslint-disable prefer-const */
/* eslint-disable default-case */
import Module from './Module';

import { returnFileSize, cleanJson, ellipsis } from './utils';
import {
	isEmptyField,
	isTextOnly,
	isEmailFormat,
	isPhoneNumber,
	isFileTypeMatching,
	isWebAddress,
} from './Validators';

// TODO
//*  [✔] Pass the error messages from a data-attribute so that webmasters could change them
//*  [✔] Implement the date validation
//*  [✔] Convert the forEach to provide support to IE11
//*  [✔] Improve the phone validation to match the pattern of different countries
//*  [✔]  Pass the error field type in the array to customize the position of the error
//  [] Find a way to only use FormData instead of serialize
//  [] Refactor the ajax call to vanilla solution
//  [] Add validation or limit for zipcode

export default class FormValidator extends Module {
	constructor(el) {
		super(el);

		this.errors = [];
		this.validators = [];

		this.entityMap = {
			'&': '&amp;',
			'<': '&lt;',
			'>': '&gt;',
			'"': '&quot;',
			"'": '&#39;',
			'/': '&#x2F;',
			'`': '&#x60;',
			'=': '&#x3D;',
		};
	}

	init() {
		if (window.jQuery) {
			if (this.dom.el instanceof jQuery) {
				this.dom.el = this.dom.el[0];
			}
		}

		this.originalTitle = document.title;

		this.form = this.dom.el.querySelector('form');
		this.inputs = this.dom.el.querySelectorAll('input');

		this.hasFileInputs =
			this.form.querySelectorAll('input[type=file]').length > 0;
		this.formPosition = this.form.offsetTop;

		this.optionalLabel = this.dom.el.dataset.optionalLabel;
		this.dataGtm = this.dom.el.dataset.gtminfo;
		this.dataGa4 = this.dom.el.getAttribute('data-ga4-form-error');
		this.submitLabel =
			this.dom.el.getAttribute('data-submit-label') || 'Submit';
		this.submitLabelDone =
			this.dom.el.getAttribute('data-submit-label-done') || 'Done';
		this.submitLabelProcessing =
			this.dom.el.getAttribute('data-submit-label-processing') || 'Processing';
		this.submitLabelError =
			this.dom.el.getAttribute('data-submit-label-error') || 'Error';

		this.presetArray = this.getPresetArray();
		this.countryFromCookie = this.checkForCookie();

		this.handleUTMFields();
		this.handleOptionals();
		this.handleLabels();
		this.handleSubmit();
		this.handleRegionSelect();
		this.prefillCountryField();
		this.handleFilesFields();
		this.handleA11yRadioFields();
		this.handleA11yFilesFields();
		this.applyFormPreset();
		this.addIdToFields();
	}

	checkForCookie() {
		let countryInCookie = document.cookie.match(
			/^(?:.*\;\s?)?bmg-localization(?:\-fallback)?\=[^\;]*country\_code=([^\;\|]+)/i
		);
		if (countryInCookie && countryInCookie.length > 0) {
			return countryInCookie[1].toLowerCase();
		}
	}

	updateCTA(state) {
		let ctaBtn = this.form.querySelector('.cta');
		switch (state) {
			case 'processing':
				ctaBtn.innerHTML = `<span class="cta__icon cta__icon--processing">
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
					<circle cx="8" cy="8" r="7.5"></circle>
				</svg>
				</span>
				${this.submitLabelProcessing}`;
				ctaBtn.classList.add('cta__processing');
				break;
			case 'processed':
				ctaBtn.innerHTML = `<span class="cta__icon cta__icon--processed">
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 45.7 45.7"><path d="M20.69 38.33a5.3 5.3 0 0 1-7.5 0L1.54 26.7a5.3 5.3 0 1 1 7.5-7.5L16 26.13c.52.52 1.37.52 1.9 0L36.63 7.37a5.3 5.3 0 0 1 7.5 7.5L20.7 38.33z"/></svg>
                    </span>
                    ${this.submitLabelDone}`;
				ctaBtn.classList.remove('cta__processing');
				break;
			case 'error':
				ctaBtn.innerHTML = `<span class="cta__icon cta__icon--error">
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 13.31 13.31"><path data-name="Tracé 24336" d="M11.6 13.02L6.67 8.07 1.7 13.02a1 1 0 0 1-1.42-1.41l4.95-4.95L.3 1.7A1 1 0 0 1 1.71.29l4.95 4.95L11.6.3a1 1 0 0 1 1.41 1.42L8.07 6.66l4.95 4.95a1 1 0 1 1-1.41 1.41z"/></svg>
                    </span>
                    ${this.submitLabelError}`;
				ctaBtn.classList.remove('cta__processing');
				break;
			case 'submit':
				ctaBtn.innerHTML = this.submitLabel;
				ctaBtn.classList.remove('cta__processing');
				break;
		}
	}

	applyFormPreset() {
		this.presetArray.forEach((preset) => {
			let el = document.getElementById(preset.id);
			if (el) {
				if (el.type === 'checkbox') {
					el.checked = preset.value === '1';
				} else {
					el.value = preset.value;
				}
			}
		});
	}

	getPresetArray() {
		let matchParam = window.location.href.match(
			/\?(?:.*\&)?formpreset\=([^\&]+)/i
		);
		if (matchParam && matchParam.length > 1) {
			let presetArray = [];
			let fields = matchParam[1].split('|');
			fields.forEach((field) => {
				let fieldData = field.split('^');
				if (fieldData.length > 1) {
					presetArray.push({
						id: fieldData[0],
						value: fieldData[1],
					});
				}
			});
			return presetArray;
		}
		return [];
	}

	getUTMParameter(theParameter) {
		let params = window.location.search.substr(1).split('&');
		for (var i = 0; i < params.length; i++) {
			let p = params[i].split('=');
			if (p[0] == theParameter) {
				return decodeURIComponent(p[1]);
			}
		}
		return false;
	}

	handleUTMFields() {
		let UTMCampaign = this.form.querySelector('input[id=UTMCampaign]');
		let UTMMedium = this.form.querySelector('input[id=UTMMedium]');
		let UTMSource = this.form.querySelector('input[id=UTMSource]');
		let UTMContent = this.form.querySelector('input[id=UTMContent]');
		let UTMTerm = this.form.querySelector('input[id=UTMTerm]');

		if (UTMCampaign) {
			UTMCampaign.value = this.getUTMParameter('utm_campaign');
		}
		if (UTMMedium) {
			UTMMedium.value = this.getUTMParameter('utm_medium');
		}
		if (UTMSource) {
			UTMSource.value = this.getUTMParameter('utm_source');
		}
		if (UTMContent) {
			UTMContent.value = this.getUTMParameter('utm_content');
		}
		if (UTMTerm) {
			UTMTerm.value = this.getUTMParameter('utm_term');
		}
	}

	handleOptionals() {
		const formFields = Array.from(this.form.querySelectorAll('.form__field'));

		formFields.forEach((formField) => {
			if (formField.dataset.noOptionalMsg) {
				return;
			} else {
				const label = formField.querySelector('.field__label');
				if (label) {
					let required = formField.querySelector('[required]');
					if (!required) {
						let span = document.createElement('span');
						span.textContent = ` ${this.optionalLabel}`;
						label.appendChild(span);
					}
				}
			}
		});
	}

	handleLabels() {
		const labels = Array.from(this.form.querySelectorAll('label'));

		labels.forEach((label) => {
			const count = label.innerText.length;
			let subLabel = label.querySelector('.field__hint');
			if (count > 35 && subLabel) {
				subLabel.style.display = 'inline-block';
			}
		});
	}

	handleSubmit() {
		// Prevent the native validation but only if the JS is loaded
		this.form.setAttribute('novalidate', true);

		this.form.addEventListener('submit', (e) => {
			e.preventDefault();

			//Clean the errors messages
			this.resetSummaryPanel();
			this.removeInlineErrors();
			this.resetPageTitle();

			// If the field for formsource exist, set its value to the current page url (pathname)
			let formUrl = this.form.querySelector('input[id=formUrl]');
			if (formUrl) {
				formUrl.value =
					location.protocol + '//' + location.hostname + location.pathname;
			}

			// Check all the validations
			let errorList = this.validateForm();

			// If needed prevent the submit then display all the errors
			if (errorList.length !== 0) {
				this.showSummary(errorList, this.formPosition);
				this.showInlineErrors(errorList);
				this.updatePageTitle(errorList);
				this.addGtm(errorList);
				this.addGa4(errorList);
				this.autoScroll();
				return false;
			}

			this.resetdropDowntoEnabled();
			this.updateCTA('processing');

			let dataObj = this.hasFileInputs
				? new FormData(this.form)
				: $(this.form).serialize();

			let ajaxOptions = {
				type: 'POST',
				url: this.form.getAttribute('action'),
				data: dataObj,
				contentType: false,
				processData: false,
				itemId: this.form.getAttribute('item'),
			};
			if (!this.hasFileInputs) {
				ajaxOptions.contentType =
					'application/x-www-form-urlencoded; charset=UTF-8';
			}

			// Make the API call
			$.ajax(ajaxOptions)
				.done((data, textStatus, jqXHR) => {
					this.updateCTA('processed');

					// Deprecated
					if (data.data_gtm) {
						for (let i = 0; i < data.data_gtm.length; i++) {
							window.dataLayer.push(data.data_gtm[i]);
						}
					}

					let hasConfirmationPage = this.dom.el.dataset.confirmationPage;
					let confirmationMessage = this.dom.el.querySelector(
						'.confirmationMessage'
					);

					this.emptyFields();

					if (hasConfirmationPage) {
						window.location = hasConfirmationPage;
					} else {
						this.form.style.display = 'none';
						confirmationMessage.style.display = 'block';
					}
				})
				.fail((jqXHR, textStatus, errorThrown) => {
					this.updateCTA('submit');
					this.resetRecaptcha();
					if (jqXHR.status == 400 && jqXHR.responseJSON != null) {
						let errorList = [];
						if (typeof jqXHR.responseJSON.ModelState !== 'undefined') {
							let errorMessages = jqXHR.responseJSON.ModelState;
							for (var key in errorMessages) {
								if (errorMessages.hasOwnProperty(key)) {
									if (key == 'dataGtm') {
										let gtm = JSON.parse(errorMessages[key]);
										for (var i = 0; i < gtm.length; i++) {
											window.dataLayer.push(gtm[i]);
										}
									} else {
										let modelState = errorMessages[key];
										let fieldKey = key;
										if (key.indexOf('data.') > -1) {
											fieldKey =
												key.substring(5).charAt(0).toLowerCase() +
												key.substring(5).substring(1);
										}

										errorList.push({
											field: fieldKey,
											message: errorMessages[key],
										});
									}
								}
							}
						} else {
							let errorMessages = jqXHR.responseJSON;

							for (var key in errorMessages) {
								if (errorMessages.hasOwnProperty(key)) {
									errorList.push({
										field: key.charAt(0).toLowerCase() + key.substring(1),
										message: errorMessages[key],
									});
								}
							}
						}

						if (errorList.length !== 0) {
							this.showSummary(errorList, this.formPosition);
							this.showInlineErrors(errorList);
							this.updatePageTitle(errorList);
							return false;
						}
					} else {
						if (jqXHR.responseJSON) {
							if (jqXHR.responseJSON.Message) {
								this.showSummary([
									{
										field: null,
										message: jqXHR.responseJSON.Message,
									},
								]);
							}
						}
					}
				});
			return false;
		});
	}

	emptyFields() {
		this.form.reset();
		var formSpans = this.form.querySelectorAll('span.field__info');
		formSpans.forEach(function (element) {
			element.innerHTML = '';
		});
	}

	selectedCountry() {
		let countryField = this.form.querySelector('select[data-type="country"]');
		if (countryField) {
			return countryField.options[countryField.selectedIndex].value;
		} else return 'no country field in this form';
	}

	prefillCountryField() {
		if (this.countryFromCookie) {
			let countryField = this.form.querySelector('select[data-type="country"]');
			for (let i = 0; i < countryField.length; ++i) {
				if (countryField.options[i].value === this.countryFromCookie) {
					countryField.value = this.countryFromCookie;
					countryField.dispatchEvent(new Event('change'));
					break;
				}
			}
		}
	}

	getErrorMessage(item, category) {
		const json = item.dataset.errorMessage;
		if (!json) {
			return 'No error message defined';
		} else {
			let jsonData = JSON.parse(json);
			switch (category) {
				case 'empty':
					return jsonData.empty ? jsonData.empty : 'No error message defined';
				case 'invalid':
					return jsonData.invalid
						? jsonData.invalid
						: 'No error message defined';
				case 'wrongType':
					return jsonData.wrongType
						? jsonData.wrongType
						: 'No error message defined';
				case 'tooLarge':
					return jsonData.tooLarge
						? jsonData.tooLarge
						: 'No error message defined';
			}
		}
	}

	validateForm() {
		// Will loop over all the inputs, selects and textareas to run the appropriate text
		let errorList = [];
		let radioName = [];

		let countrySelected = this.selectedCountry();
		let requiredItems = this.form.querySelectorAll('input, select, textarea');

		for (let i = 0; i < requiredItems.length; i++) {
			let item = requiredItems[i];
			let textOnly = item.dataset.textOnly === 'true';
			switch (item.type) {
				case 'text': {
					if (isEmptyField(item.value)) {
						if (item.hasAttribute('required')) {
							errorList.push({
								field: item.id,
								message: this.getErrorMessage(item, 'empty'),
							});
							break;
						}
					} else if (textOnly && !isTextOnly(item.value)) {
						errorList.push({
							field: item.id,
							message: this.getErrorMessage(item, 'invalid'),
						});
						break;
					}
					break;
				}

				case 'email': {
					if (isEmptyField(item.value)) {
						if (item.hasAttribute('required')) {
							errorList.push({
								field: item.id,
								message: this.getErrorMessage(item, 'empty'),
							});
							break;
						}
					} else if (!isEmailFormat(item.value)) {
						errorList.push({
							field: item.id,
							message: this.getErrorMessage(item, 'invalid'),
						});
						break;
					}
					break;
				}

				case 'number': {
					if (isEmptyField(item.value)) {
						if (item.hasAttribute('required')) {
							errorList.push({
								field: item.id,
								message: this.getErrorMessage(item, 'empty'),
							});
							break;
						}
					} else if (item.validity.badInput) {
						errorList.push({
							field: item.id,
							message: this.getErrorMessage(item, 'invalid'),
						});
						break;
					} else if (
						item.validity.rangeUnderflow ||
						item.validity.rangeOverflow ||
						item.validity.stepMismatch
					) {
						errorList.push({
							field: item.id,
							message: this.getErrorMessage(item, 'invalid'),
						});
						break;
					}
					break;
				}

				case 'tel': {
					if (item.hasAttribute('required')) {
						if (item.validity.valueMissing) {
							errorList.push({
								field: item.id,
								message: this.getErrorMessage(item, 'empty'),
							});
							break;
						}
					}
					// We could run test matching a pattern depending of the country selected
					if (
						item.value.length !== 0 &&
						!isPhoneNumber(item.value, countrySelected)
					) {
						errorList.push({
							field: item.name,
							message: this.getErrorMessage(item, 'invalid'),
						});
					}
					break;
				}

				case 'url': {
					if (isEmptyField(item.value)) {
						if (item.hasAttribute('required')) {
							errorList.push({
								field: item.id,
								message: this.getErrorMessage(item, 'empty'),
							});
							break;
						}
					} else if (!isWebAddress(item.value)) {
						errorList.push({
							field: item.id,
							message: this.getErrorMessage(item, 'invalid'),
						});
						break;
					}
					break;
				}

				case 'date': {
					if (item.validity.valueMissing) {
						if (item.hasAttribute('required')) {
							errorList.push({
								field: item.id,
								message: this.getErrorMessage(item, 'empty'),
							});
							break;
						}
					} else if (!item.checkValidity()) {
						errorList.push({
							field: item.id,
							message: this.getErrorMessage(item, 'invalid'),
						});
						break;
					}
					break;
				}

				case 'select-one': {
					if (item.hasAttribute('required') && item.selectedIndex === 0) {
						errorList.push({
							field: item.id,
							message: this.getErrorMessage(item, 'empty'),
						});
						break;
					}
					break;
				}

				case 'textarea': {
					if (item.hasAttribute('required') && !item.textLength) {
						errorList.push({
							field: item.id,
							message: this.getErrorMessage(item, 'empty'),
						});
						break;
					} else if (!item.checkValidity()) {
						errorList.push({
							field: item.id,
							message: this.getErrorMessage(item, 'invalid'),
						});
						break;
					}
					break;
				}

				case 'radio': {
					if (item.required) {
						if (radioName.indexOf(item.name) > -1) {
							break;
						} else {
							radioName.push(item.name);
							if (
								this.form.querySelectorAll(`input[name=${item.name}]:checked`)
									.length === 0
							) {
								errorList.push({
									field: item.id,
									message: this.getErrorMessage(item, 'invalid'),
								});
								break;
							}
						}
					}
					break;
				}

				case 'file': {
					if (item.hasAttribute('required') && !item.files.length) {
						errorList.push({
							field: item.id,
							message: this.getErrorMessage(item, 'empty'),
						});
						break;
					} else if (item.files.length > 0) {
						if (!isFileTypeMatching(item.files[0].type, item.accept)) {
							errorList.push({
								field: item.id,
								message: this.getErrorMessage(item, 'wrongType'),
							});
							break;
						} else if (item.files[0].size > parseInt(item.dataset.sizeCap)) {
							errorList.push({
								field: item.id,
								message: this.getErrorMessage(item, 'tooLarge'),
							});
							break;
						}
					}
					break;
				}

				case 'checkbox': {
					if (item.hasAttribute('required') && !item.checked) {
						errorList.push({
							field: item.id,
							message: this.getErrorMessage(item, 'invalid'),
						});
						break;
					}
					break;
				}
			}
		}

		return errorList;
	}

	removeInlineErrors() {
		Array.from(this.form.querySelectorAll('.error')).forEach((e) =>
			e.parentNode.removeChild(e)
		);
		Array.from(this.form.querySelectorAll('.invalid')).forEach((e) => {
			e.setAttribute('aria-invalid', false);
			e.classList.remove('invalid');
		});
	}

	updatePageTitle(errorList) {
		document.title = `(${errorList.length} errors) - ${this.originalTitle}`;
	}

	resetPageTitle() {
		document.title = this.originalTitle;
	}

	resetSummaryPanel() {
		this.dom.el.querySelector('.errorSummary').style.display = 'none';
		this.dom.el.querySelector('.errorList').innerHTML = '';
	}

	resetRecaptcha() {
		if (window.grecaptcha) {
			window.grecaptcha.reset();
		}
	}

	showSummary(errorList, position) {
		let errorSummary = this.dom.el.querySelector('.errorSummary');
		errorSummary.style.display = 'block';
		errorList.forEach((error) => {
			this.createErrorLink(error.message, error.field);
		});
		window.scrollTo(0, position - 60);
		this.dom.el
			.querySelector('#errorSummary-heading')
			.focus({ preventScroll: true });
	}

	showInlineErrors(errorList) {
		errorList.forEach((error) => {
			this.createErrorMessage(error.field, error.message);
		});
	}

	resetdropDowntoEnabled() {
		if (
			this.dom.el.querySelector('#job') &&
			this.dom.el.querySelector('#job').getAttribute('disabled') !== null
		) {
			this.dom.el.querySelector('#job').removeAttribute('disabled');
		}
	}

	// Create a new <div class="error"> with the error message between the Label and the field
	createErrorMessage(field, message) {
		let newError = document.createElement('span');
		newError.className = 'error';
		newError.innerHTML = message;
		let fieldPosition = this.dom.el.querySelector(`[for="${field}"]`);
		let fieldUpload = fieldPosition.querySelector('.field__upload');
		const fieldNode = this.dom.el.querySelector(`#${field}`);
		const fieldType = fieldNode.type;

		if (fieldType === 'radio' || fieldType === 'checkbox') {
			let legend = fieldPosition.parentElement.parentElement.querySelector(
				'legend'
			);
			legend.appendChild(newError);
			fieldPosition.parentElement.classList.add('invalid');
		} else if (fieldPosition && fieldUpload) {
			fieldPosition.insertBefore(newError, fieldUpload);
			fieldUpload.classList.add('invalid');
		} else {
			fieldPosition && fieldPosition.appendChild(newError);
		}
		fieldNode.classList.add('invalid');
		fieldNode.setAttribute('aria-invalid', true);
	}

	// Create a new <li><a> with the error message linking to the field with error
	createErrorLink(message, field = null) {
		let newListItem = document.createElement('li');
		if (field != null) {
			let newLink = document.createElement('a');
			newLink.setAttribute('href', `#${field}_anchor`);
			newLink.innerHTML = message;
			newListItem.appendChild(newLink);
		} else {
			let newSpan = document.createElement('span');
			newSpan.innerHTML = message;
			newListItem.appendChild(newSpan);
		}

		this.dom.el.querySelector('.errorList').appendChild(newListItem);
	}

	handleRegionSelect() {
		let countrySelect = this.dom.el.querySelector(
			'select[data-type="country"]'
		);
		let regionSelect = this.dom.el.querySelector('select[data-type="region"]');

		if (regionSelect) {
			let regionWrapper = regionSelect.parentElement;
			let regionLabel = regionWrapper.querySelector('.field__label');

			let originalOptions = regionSelect.innerHTML;

			countrySelect.addEventListener('change', () => {
				let target = regionSelect;
				target.innerHTML = originalOptions;
				let options = Array.from(target.querySelectorAll('optgroup'));
				let match = 0;
				options.forEach((option) => {
					let optgroup = target.querySelector(
						`optgroup[label=${option.label}]`
					);
					if (countrySelect.value === option.label) {
						target.removeAttribute('disabled');
						target.setAttribute('required', true);
						target.setAttribute('aria-required', true);
						optgroup.removeAttribute('hidden');
						target.value = '';
						regionWrapper.removeAttribute('style');
						regionLabel.innerHTML = optgroup.dataset.label;
						target.options[0].innerText = optgroup.dataset.placeholder;
						match += 1;
					} else {
						optgroup.outerHTML = '';
					}
				});
				if (match === 0) {
					target.setAttribute('disabled', true);
					target.setAttribute('aria-required', false);
					target.removeAttribute('required');
					target.value = '';
					regionWrapper.setAttribute('style', 'display: none;');
				}
			});
		}
	}

	handleFilesFields() {
		let filesFields = Array.from(
			this.dom.el.querySelectorAll('input[type="file"]')
		);
		filesFields.forEach((field) => {
			let removeBtn = field.parentElement.querySelector('.field__btn--remove');
			let targetSpan = field.parentElement.querySelector('.field__info');
			let uploadBtn = field.parentElement.querySelector('.field__btn--upload');

			field.addEventListener('change', () => {
				if (field.files.length !== 0) {
					removeBtn.style.display = 'flex';
					uploadBtn.style.display = 'none';
				} else {
					removeBtn.style.display = 'none';
					uploadBtn.style.display = 'flex';
				}
				this.updateFileSFieldMessage(field, targetSpan);
			});

			this.handleRemoveFilesBtns(field, removeBtn, targetSpan);
		});
	}

	handleRemoveFilesBtns(input, button, target) {
		button.addEventListener('click', () => {
			input.value = '';
			this.updateFileSFieldMessage(input, target);
			let event = new Event('change');
			input.dispatchEvent(event);
		});
	}

	updateFileSFieldMessage(input, target) {
		if (input.files.length !== 0) {
			let fileSize = input.files[0].size;
			let fileName = input.files[0].name;
			let ellipsName = ellipsis(15, fileName);
			target.innerHTML = `${ellipsName} - ${returnFileSize(fileSize)}`;
		} else {
			target.innerHTML = `No file selected`;
		}
	}

	// Add keyboard functionality to the fields of type file
	handleA11yFilesFields() {
		let filesBtns = Array.from(this.dom.el.querySelectorAll('.field__upload'));
		filesBtns.forEach((filesBtn) => {
			filesBtn.addEventListener('keypress', (e) => {
				if (e.keyCode === 32 || e.keyCode === 13) {
					e.preventDefault();
					let target = filesBtn.parentElement.nextElementSibling;
					target.click();
				}
			});
		});
	}

	// Add keyboard functionality to the fields of type radio
	handleA11yRadioFields() {
		let radioLabels = Array.from(this.dom.el.querySelectorAll('.radio__btn'));
		radioLabels.forEach((radioLabel) => {
			radioLabel.addEventListener('keypress', (e) => {
				if (e.keyCode === 32 || e.keyCode === 13) {
					e.preventDefault();
					radioLabel.previousElementSibling.checked = true;
				}
			});
		});
	}

	addGtm(errorList) {
		if (this.dataGtm) {
			let errorMessages = [];
			errorList.forEach((error) => {
				errorMessages.push(error.message);
			});
			errorMessages = errorMessages.join('|');
			this.dataGtm = this.dataGtm.replace('%eventLabel%', errorMessages);
			const dataGtmCleanJson = cleanJson(this.dataGtm);
			window.dataLayer.push(dataGtmCleanJson);
		}
	}

	addGa4(errorList) {
		if (!this.dataGa4) return;
		try {
			const errorMessages = errorList
				.map((error) => `${error.field} - ${error.message}`)
				.join('|');

			this.dataGa4 = this.dataGa4.replace('<message>', errorMessages);
			const ga4StringParsed = cleanJson(this.dataGa4);
			if (Array.isArray(ga4StringParsed)) {
				for (let i = 0; i < ga4StringParsed.length; i++) {
					window.dataLayer.push(ga4StringParsed[i]);
				}
			} else {
				window.dataLayer.push(ga4StringParsed);
			}
		} catch (e) {
			console.warn('[ModuleFormValidator] - Error while parsing GA4 Events');
		}
	}

	addIdToFields() {
		const fieldList = Array.from(document.querySelectorAll('.form__field'));
		fieldList.forEach((item) => {
			const input = item.querySelector('input');
			if (input) {
				item.id = input.id + '_anchor';
			}
		});
	}

	autoScroll() {
		let thisModule = this;
		var scrollingLinks = this.dom.el.querySelectorAll('a[href^="#"]');

		if (!scrollingLinks) {
			return;
		}
		[].forEach.call(scrollingLinks, (element) => {
			let target = document.getElementById(element.hash.substring(1));

			if (target) {
				element.addEventListener('click', (e) => {
					if (
						getComputedStyle(target, null).display === 'none' ||
						target.nodeName === 'INPUT' ||
						target.nodeName === 'SELECT'
					) {
						thisModule.doScrolling(
							thisModule.getElementYPosition(target.parentElement) - 90,
							200
						);
					} else {
						thisModule.doScrolling(
							thisModule.getElementYPosition(target) - 60,
							500
						);
					}
				});
			}
		});
	}

	getElementYPosition(element) {
		var y = element.offsetTop;
		var node = element;

		while (node.offsetParent && node.offsetParent != document.body) {
			node = node.offsetParent;
			y += node.offsetTop;
		}

		return y;
	}

	doScrolling(elementY, duration) {
		var startingY = window.pageYOffset;
		var diff = elementY - startingY;
		var start;

		window.requestAnimationFrame(function step(timestamp) {
			if (!start) start = timestamp;
			var time = timestamp - start;
			var percent = Math.min(time / duration, 1);

			window.scrollTo(0, startingY + diff * percent);

			if (time < duration) {
				window.requestAnimationFrame(step);
			}
		});
	}
}
