import Tooltip from 'tooltip.js';
import Module from './Module';

export default class ModuleTooltips extends Module {
	init() {
		this.tooltip = this.dom.el;
		this.placement = this.dom.el.dataset.tooltipPlacement
			? this.dom.el.dataset.tooltipPlacement
			: 'right';

		this.tooltip.addEventListener('click', (e) => {
			e.preventDefault();
		});

		this.handleTooltip();
	}

	handleTooltip() {
		const container = document.querySelector('body');

		const instance = new Tooltip(this.tooltip, {
			title: this.tooltip.dataset.tooltip,
			trigger: 'click',
			closeOnClickOutside: true,
			placement: this.placement,
			html: false,
			template:
				'<div class="tooltip" tabindex="0" role="tooltip"><div class="tooltip-arrow"></div><div class="tooltip-inner"></div><button type="button" class="tooltip-close"><span class="sr-only">Close Tooltip</span><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 8.61 8.61" width="8.61px" height="8.61px"><path class="tooltip-close-svg" d="M7.5 8.42l-3.2-3.2-3.2 3.2a.65.65 0 0 1-.9-.91l3.2-3.2L.2 1.1a.65.65 0 0 1 .9-.92l3.2 3.2L7.5.2a.65.65 0 0 1 .92.92l-3.2 3.2 3.2 3.2a.65.65 0 0 1-.91.91z" fill="#d100d0"/></svg></button></div>',
			popperOptions: {
				onCreate: () => {
					this.handleClose();
				},
				modifiers: {
					flip: {
						behavior: ['right', 'bottom', 'top'],
					},
					preventOverflow: {
						boundariesElement: container,
					},
				},
			},
		});
	}

	handleClose() {
		const closeBtn = this.tooltip.parentElement.querySelector('.tooltip-close');

		this.dom.body.querySelector('.tooltip').focus();

		this.dom.body.querySelector('.tooltip').addEventListener('click', (e) => {
			e.preventDefault();
		});

		closeBtn.addEventListener('click', () => {
			this.tooltip.click();
			this.tooltip.focus();
			this.tooltip.blur();
		});

		closeBtn.addEventListener('keyup', (e) => {
			if (e.keyCode === 32 || e.keyCode === 13) {
				this.tooltip.click();
				this.tooltip.focus();
			}
		});

		closeBtn.addEventListener('keydown', (e) => {
			if (document.activeElement === closeBtn && e.keyCode === 9) {
				e.preventDefault();
			}
		});
	}
}
